<template>
  <div>
    <eden-page-header
      :title="'Rider Directory'"
      :subtitle="$route.params.name"
      :section="formatDate(payout.date_payed_for)"
    >
    </eden-page-header>
    <eden-page-stats :title="'Overview'" :stats="stats" />

    <eden-table-actions :title="title" @search="searchOrders" />
    <el-table :data="setOrders">
      <el-table-column min-width="100">
        <template slot="header">
          <div class="table--header">
            <span>Order Id</span>
          </div>
        </template>
        <template slot-scope="scope">
          <div>
            <p class="text-primary">
              {{ scope.row.order_number }}
            </p>
          </div>
        </template>
      </el-table-column>
      <el-table-column>
        <template slot="header">
          <div class="table--header">
            <span>Customer</span>
            <i class="el-icon-bottom"></i>
          </div>
        </template>
        <template slot-scope="scope">
          <p>
            {{ formatText(scope.row.recipient?.customer_name) }}
          </p>
        </template>
      </el-table-column>
      <el-table-column>
        <template slot="header">
          <div class="table--header">
            <span>Phone Number</span>
            <i class="el-icon-bottom"></i>
          </div>
        </template>
        <template slot-scope="scope">
          <p>
            {{ formatPhoneNumber(scope.row.recipient?.customer_phone) }}
          </p>
        </template>
      </el-table-column>
      <el-table-column>
        <template slot="header">
          <div class="table--header">
            <span>Address</span>
            <i class="el-icon-bottom"></i>
          </div>
        </template>
        <template slot-scope="scope">
          <span class="font-sm">
            {{ scope.row.delivery_address?.address }}
          </span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import riders from "@/requests/marketplace-logistics/riders";
export default {
  name: "RiderOrders",

  data() {
    return {
      payout: [],
      pagination: {},
      page: 1,
      loading: false,
      searchQuery: "",
    };
  },

  computed: {
    title() {
      return `${this.payout.orders?.length} Orders`;
    },
    riderId() {
      return this.$route.params.id;
    },
    payoutStatusTag() {
      return {
        type: this.setPayoutStatusTagType(this.payout.status),
        text: this.formatText(this.payout.status),
      };
    },
    stats() {
      const statusStat = {
        label: "Payout Status",
        figure: null,
        statusTag: this.payoutStatusTag,
      };
      return [
        {
          label: "Orders Delivered",
          figure: this.payout.orders_delivered_count,
        },
        {
          label: "Payout Amount",
          figure: this.formatPrice(this.payout.total_delivery_fee_paid),
        },
        statusStat,
      ];
    },
    setOrders() {
      const query = this.searchQuery.toLowerCase();

      if (query) {
        return this.payout?.orders.filter((order) => {
          return (
            order.recipient?.customer_name.toLowerCase().includes(query) ||
            order.order_number.toLowerCase().includes(query)
          );
        });
      } else {
        return this.payout?.orders;
      }
    },
  },
  created() {
    this.getPayoutDetails();
  },
  methods: {
    setPayoutStatusTagType(status) {
      return status === "Completed" ? "success" : "warning";
    },
    getPayoutDetails() {
      this.loading = true;
      riders
        .payoutDetails(this.riderId)
        .then((response) => {
          if (response.data.status) {
            this.payout = response.data.data;
            this.pagination = response.data.meta;
          }
        })
        .catch((error) => {
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    searchOrders(query) {
      this.searchQuery = query;
      this.page = 1;
      this.getPayoutDetails();
    },
  },
};
</script>
